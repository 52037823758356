<template>
    <div class="form-group row">
        <div class="col-lg-4 col-md-3">
            <label class="col-form-label control-label">Redeem Points</label>
        </div>
        <div class="col-md-6 row">
            <div class="col-md-3" v-if="this.edititem == null && this.referralPoints == null">
                <input type="button" @click="fetchPoints()" class="btn btn-primary mt-5px" value=" Fetch" />
            </div>
            <div class="col-md-3" v-if="parseFloat(this.allpoints) > 0">
                <input type="text" class="form-control form-cascade-control input-small" :value="allpoints" disabled>
            </div>
            <div class="col-md-3" v-if="parseFloat(this.allpoints) > 0">
                <input type="text" class="form-control form-cascade-control input-small"
                    placeholder="Enter point to redeem" v-model="redeempoints" :disabled="!inputdisable"
                    @input="validatePoints()">
            </div>
            <div class="col-md-3" v-if="parseFloat(this.allpoints) > 0">
                <input type="button" v-if="this.inputdisable" class="btn btn-primary" @click="saveChanges()"
                    value="Save" />
                <input type="button" v-else class="btn btn-primary" @click="fetchPoints()" value="Edit" />
            </div>
            <!-- edit -->
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import axios from 'axios'



export default {
    mixins: [Constants],
    data() {
        return {
            allpoints: 0,
            redeempoints: 0,
            inputdisable: false,
            finalpoint: 0
        }
    },
    mounted() {
        this.fetchPoints()
        if (this.referralPoints != null && parseFloat(this.referralPoints.amount) > 0) {
            this.finalpoint = parseFloat(this.referralPoints.amount).toFixed(2);
        }
    },
    computed: {
        ...mapGetters(['referralPoints', 'referraluser', 'edititem'])
    },
    methods: {
        fetchPoints() {
            if (this.referraluser != null && this.referraluser.id > 0) {
                let param = { customerid: this.referraluser?.id }
                window.axios.post('api/customer/points/fetch', param)
                    .then((response) => {
                        let points = response.data.ledger
                        if (points != null) {
                            if (this.edititem == null) {
                                this.allpoints = points.balance
                            } else {
                                this.allpoints = parseFloat(parseFloat(points.balance) + parseFloat(this.finalpoint)).toFixed(2);
                                this.redeempoints = parseFloat(this.referralPoints?.amount).toFixed(2) ?? 0;
                            }
                            this.inputdisable = true;
                        } else {
                            this.allpoints = 0
                            this.$notify({ text: 'No points found.', type: 'warning', })
                        }
                    })
                    .catch((error) => console.log(error))
            }
        },
        validatePoints() {
            if (parseFloat(this.redeempoints) == 0 || isNaN(this.redeempoints) || this.redeempoints == '') {
                this.$store.commit('assignreferralPoints', null)
                this.redeempoints = 0
            }
           
            if (parseFloat(this.redeempoints) > parseFloat(this.allpoints)) {
                this.$notify({ text: 'Existing Points is less than the required points.', type: 'error', })
                this.redeempoints = 0
                return false
            }
            this.$emit("updateValue", this.redeempoints);
            return true
        },
        saveChanges() {
            let param = { userid: this.referraluser?.id }
            axios.post('api/user/condition/verify', param)
                .then((response) => {
                    if (response.data.condition?.disable_redeem == 1) {
                        this.$notify({ text: 'Points cannot be reedeem.', type: 'error', })
                    } else {
                        this.referralObject()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.$notify({ text: 'Error occurred while verifying user.', type: 'error', })
                })
        },
        referralObject(){
            if (parseFloat(this.redeempoints) > 0) {
                let referrals = {
                    amount: parseFloat(this.redeempoints).toFixed(2), inout: 1,
                    mode: 0, paymenttype: this.PAYMENT_BY_POINTS
                }
                this.$store.commit('assignreferralPoints', referrals)
                this.inputdisable = false
            } else {
                this.$store.commit('assignreferralPoints', null)
            }
        }

    }
}
</script>