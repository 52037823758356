<template>
    <div>
        <div class="col-md-12" style="margin-top:20px">
            <div class="col-md-12">
                <div class="row" style="margin-top:20px">
                    <div class="col-md-6">
                        <h3 class="page-header">
                            <i class="fa fa fa-user animated bounceInDown show-info"></i>
                            <span v-if="this.trantype==1">Add Purchase</span>
                            <span v-if="this.trantype==2">New Sales</span>
                        </h3>
                    </div>
                    <div class="col-md-6" v-if="edititem != null">
                        <div class="row" style="padding-top:18px;justify-content:flex-end;">
                            <div class="col-md-2" style="margin-right:25px">
                                <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i>
                                    Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-cascade">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <div class="col-md-12">
                            <form>
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Sale Type<span
                                                style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu"
                                                style="background-color: rgb(255, 255, 255);" @change="saletypechange()"
                                                v-model="form.saletype" required>
                                                <option :value="2">EMI</option>
                                                <option :value="1">Cash</option>
                                            </select>
                                            <font color="red"><span class="field-validation-valid"
                                                    data-valmsg-for="LST_BRANCH" data-valmsg-replace="true"></span>
                                            </font>
                                        </div>
                                    </div>
                                    <div class="form-group row" v-if="form.saletype == 2"
                                        :class="{ 'form-group--error': $v.manualid.$error }">
                                        <div class="col-lg-4 col-md-3">
                                            <label for="gender" class="col-form-label control-label">LAN NO.<span
                                                    style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                        </div>
                                        <div class="col-md-6">
                                            <input type="text" v-model.trim="$v.manualid.$model" @input="checklan()"
                                                :class="this.showlanmsg ? 'form-control form-cascade-control input-small ' : 'form-control form-cascade-control input-small div_error'"
                                                required>
                                            <span v-if="showlanmsg == false">
                                                <strong style="color:red">Already Exists</strong>
                                            </span>
                                            <span class="error" v-if="!$v.manualid.required && showlanmsg">Field is
                                                required </span>
                                            <span class="error" v-if="!$v.manualid.minLength"> - Name must have at least
                                                {{ $v.manualid.$params.minLength.min }} letters.</span>
                                            <!-- <div class="error" v-if="!$v.manualid.isUnique">FK is not found</div> -->
                                            <!-- <tree-view :data="$v.manualid" :options="{rootObjectKey: '$v.manualid', maxDepth: 2}"></tree-view> -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Sale No.<span
                                                style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" v-model="serialno" disabled
                                                class="form-control form-cascade-control input-small">
                                            <font color="red"><span class="field-validation-valid"
                                                    data-valmsg-for="LST_BRANCH" data-valmsg-replace="true"></span>
                                            </font>
                                        </div>
                                    </div>
                                    <input type="checkbox" v-model="newcustomer">
                                    <template v-if="newcustomer == false">
                                        <label class="col-lg-4 col-md-3 control-label">NewCustomer</label>
                                        <referral-selector col="col-lg-3 col-md-3" label="Customer Id"
                                            ref="resetid"></referral-selector>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-md-3 control-label">Customer Introducer
                                                <span style="color:rgba(255, 0, 0, 0.67)">*</span>
                                            </label>
                                            <div class="col-lg-8 col-md-9"
                                                v-if="referraluser != null && referraluser.referraluser != null">
                                                <span style="font-weight:700">{{ referraluser.referraluser.generatedid
                                                    }}</span> -- {{ referraluser.referraluser.name }}
                                                <display-mobiles
                                                    :mobiles="referraluser.referraluser.mobile"></display-mobiles>
                                                <display-first-address
                                                    :addressess="referraluser.addressess"></display-first-address>
                                            </div>
                                        </div>

                                    </template>
                                    <template v-if="newcustomer == 1">
                                        <label class="col-lg-4 col-md-3 control-label">Customer Id</label>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="gender" class="col-form-label control-label">New
                                                    Customer<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" v-model="form.customername"
                                                    class="form-control form-cascade-control input-small">
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="gender" class="col-form-label control-label">Customer
                                                    Mobile<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" v-model="form.mobileno"
                                                    class="form-control form-cascade-control input-small">
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <hr>
                                    <div class="col-md-12 text-right pb-20">
                                        <button class="badge btn-success btn-xs" type="button" @click="openproducts()">
                                            <span v-if="showcart == false && edititem == null">Add Sales</span>
                                            <span v-if="showcart == true && edititem == null">Hide Sales</span>
                                            <span v-if="edititem != null">Show Sales</span>
                                        </button>
                                    </div>
                                    <div v-if="showcart">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="subcategory" class="col-form-label control-label">Select
                                                    Category</label>
                                            </div>
                                            <div class="col-md-6">
                                                <select class="form-control form-cascade-control input-small"
                                                    v-model="parentid" ref="myInput">
                                                    <option selected>----Select-----</option>
                                                    <option v-for="item in parentcategory" v-bind:value="item.id"
                                                        :key="item.id">{{ item.name }}</option>
                                                </select>
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="subcategory" class="col-form-label control-label">Select
                                                    Product</label>
                                            </div>
                                            <div class="col-md-6">
                                                <select @change="fetchbrands()"
                                                    class="form-control form-cascade-control input-small"
                                                    v-model="category_id">
                                                    <option selected>----Select-----</option>
                                                    <option v-for="item in subcategory" v-bind:value="item.id"
                                                        :key="item.id">{{ item.name }}</option>
                                                </select>
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class=" col-lg-4 col-md-3">
                                                <label for="subcategory"
                                                    class="col-form-label control-label">Company</label>
                                            </div>
                                            <div class="col-md-6">
                                                <select v-model="brandid" @change="fetchproducts()"
                                                    class="form-control form-cascade-control input-small">
                                                    <option value="0">---Select---</option>
                                                    <option v-for="filter in brands" :key="filter.id"
                                                        :value="filter.id">
                                                        {{ filter.value }}</option>
                                                </select>
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="firstname" class="col-form-label control-label">Model
                                                    Name<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <select v-model="form.productid" @change="getAllBarcode()"
                                                    class="form-control form-cascade-control input-small">
                                                    <option v-for="filter in products" :key="filter.id"
                                                        :value="filter.id">
                                                        {{ filter.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="firstname" class="col-form-label control-label">Remain
                                                    Quantity<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" class="form-control form-cascade-control input-small"
                                                    v-model="remainquantity" disabled />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="firstname"
                                                    class="col-form-label control-label">Quantity<span
                                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" class="form-control form-cascade-control input-small"
                                                    v-model="quantity" @input="checkQuantity()" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <label for="gender" class="col-form-label control-label">Serial No./
                                                    IMEI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <select v-model="barcodeid" @change="fillBarcode()"
                                                    class="form-control form-cascade-control input-small">
                                                    <option v-for="filter in allbarcode" :key="filter.id"
                                                        :value="filter.id">
                                                        <span>{{ filter.barcode }}</span> <span v-if="filter.colour"> /
                                                            {{
                                                                filter.colour }}</span>
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                            </div>
                                            <div class="col-md-6">
                                                <div v-if="this.barcodes.length > 0" class="row">
                                                    <div class="col-md-6" v-for="item in barcodes" :key="item.id">
                                                        <div class="form-control form-cascade-control input-small pr"
                                                            style="margin-top: 5px;"> <span>{{
                                                                item.barcode }}</span> <span v-if="item.colour">/ {{
                                                                    item.colour }}</span>
                                                        </div>
                                                        <span class="trash_bin" @click="deleteBarcode(item)"><i
                                                                class='bx bxs-trash font-17'></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="btn bg-primary text-white btn-md"
                                            :disabled="this.quantity > 0 && this.quantity == this.chooseBarcode ? false : true"
                                            @click="addproduct()">
                                            <span v-if="form.productid != 0">Add In Cart</span>
                                            <span v-if="form.productid == 0">Select Product</span>
                                        </button>
                                        <span v-show="showmsg == true" style="color:red"> Select Product First</span>

                                        <cart @name="getval" :discount="this.discount"></cart>

                                        <hr>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="gender"
                                                    class="col-form-label control-label">Discount</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div> <input type="number" v-model="discount" @input="discountchange()"
                                                        class="form-control form-cascade-control input-small"></div>
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="gender" class="col-form-label control-label">Cash Sale Price
                                                    <span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <div> <input type="text" disabled v-model="totalnetpriceafterdiscount"
                                                        class="form-control form-cascade-control input-small"></div>
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="false">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="gender" class="col-form-label control-label">S.No./IMEI
                                                    No.<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" v-model="form.field1"
                                                    class="form-control form-cascade-control input-small">
                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                            </div>
                                        </div>
                                        <loan-fields v-if="form.saletype == 2" :amount="totalnetpriceafterdiscount"
                                            :manualid="manualid" :startdate="startdate"></loan-fields>

                                        
                                        <div class="form-group row"
                                            :class="{ 'form-group--error': $v.form.saledate.$error }">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="date" class="col-form-label control-label">Sale Date<span
                                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <!-- <input type="date" placeholder="dd-mm-yyyy" v-model="$v.form.saledate.$model" class="form-control form-cascade-control input-small" min="2021-10-01" required> -->
                                                <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                                    v-model="$v.form.saledate.$model">
                                                    <template v-slot="{ inputValue, togglePopover }">
                                                        <div class="flex items-center">
                                                            <button type="button" class="date-picker"
                                                                @click="togglePopover()">
                                                                <i class='bx bx-calendar'></i>
                                                            </button>
                                                            <input :value="inputValue" @click="togglePopover()"
                                                                min="2022-03-31"
                                                                class="form-control form-cascade-control input-small"
                                                                placeholder="DD/MM/YYYY" readonly required />
                                                        </div>
                                                    </template>
                                                </vc-date-picker>
                                                <div class="error" v-if="!$v.form.saledate.isUnique">Input Valid Date
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-md-3 control-label">Mode of Payment<span
                                                    style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            <div class="col-lg-6 col-md-9">
                                                <select class="form-control form-cascade-control input-small"
                                                    v-model="mode">
                                                    <option v-for="item in registers" :key="item.id" :value="item.id">
                                                        {{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="form.saletype == 2"
                                            :class="{ 'form-group--error': $v.startdate.$error }">
                                            <div class="col-lg-4 col-md-3">
                                                <label for="date" class="col-form-label control-label">Emi Date<span
                                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>

                                            </div>
                                            <div class="col-md-6">
                                                <!-- <input type="date" v-model="$v.startdate.$model" class="form-control form-cascade-control input-small" required> -->
                                                <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                                    v-model="$v.startdate.$model">
                                                    <template v-slot="{ inputValue, togglePopover }">
                                                        <div class="flex items-center">
                                                            <button type="button" class="date-picker"
                                                                @click="togglePopover()">
                                                                <i class='bx bx-calendar'></i>
                                                            </button>
                                                            <input :value="inputValue" @click="togglePopover()"
                                                                min="2022-03-31"
                                                                class="form-control form-cascade-control input-small"
                                                                placeholder="DD/MM/YYYY" readonly required />
                                                        </div>
                                                    </template>
                                                </vc-date-picker>
                                                <div class="error" v-if="!$v.startdate.isUnique">Input Valid Date</div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label class="col-form-label control-label">Deal By<span
                                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <select v-model="form.dealbyid"
                                                    class="form-control form-cascade-control input-small" required>
                                                    <option v-for="filter in employees" :key="filter.id"
                                                        :value="filter.id">
                                                        {{ filter.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label class="col-form-label control-label">Delivered By<span
                                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                            </div>
                                            <div class="col-md-6">
                                                <select v-model="form.salebyid"
                                                    class="form-control form-cascade-control input-small" required>
                                                    <option v-for="filter in employees" :key="filter.id"
                                                        :value="filter.id">
                                                        {{ filter.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-3">
                                                <label class="col-form-label control-label">Sale By</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" class="form-control form-cascade-control input-small"
                                                    v-model="form.useradvisorid">
                                                <button type="button" @click="checkadvisor()" class="inputsearchbtn"><i
                                                        class='bx bxs-right-arrow-square ft-49'></i></button>
                                                <span v-show="isadvisor">
                                                    <strong style="color:green">{{ this.advisorid.name }} is a
                                                        Advisor</strong>
                                                </span>
                                                <span v-show="showadvice">
                                                    <strong style="color:red">Not A advisor</strong>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <div class="col-md-3">

                                            </div>
                                            <div class="col-md-4">
                                                <button class="btn bg-primary text-white btn-sm " type="button"
                                                    @click="submit()" style="padding:6px 20px;width: 50%;"
                                                    :disabled="btndisabled == true ? true : false">Submit
                                                </button>
                                            </div>
                                            <div class="col-md-3">
                                                <button @click="reset()" class="btn bg-primary text-white btn-sm "
                                                    type="button" style="padding:6px 20px;width: 50%;">Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ReferralSelector from '../../components/user/ReferralSelector.vue'
import Constants from '../../components/utilities/Constants.vue'
//import UserSelectorByEvent from '../../components/user/UserSelectorByEvent.vue'
import LoanFields from '../loan/LoanFields.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Cart from '../cart/Cart.vue'
import { required, minLength } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
    mixins: [Constants],
    components: { Cart, ReferralSelector, LoanFields, DisplayFirstAddress, DisplayMobiles },
    data() {
        return {
            form: new window.Form({
                id: 0, saletype: 2, customerid: 0, productid: 0, color: '', salebyid: 0, saledate: null,
                customername: '', mobileno: null, category_id: 0, field1: '', useradvisorid: 'VSDIGI-', dealbyid: 0,
            }),
            brandid: 0,
            products: [],
            manualid: 'FK-',
            newcustomer: false,
            parentid: 0,
            category_id: 0,
            discount: 0,
            mode: '',
            brands: [],
            showcart: false,
            serialno: 0,
            showmsg: false,
            startdate: null,
            showlanmsg: true,
            showadvice: false,
            isadvisor: false,
            moment: moment,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            btndisabled: false,
            quantity: 0,
            barcodeid: 0,
            allbarcode: [],
            barcodes: [],
            chooseBarcode: 0,
            remainquantity: 0,
            totalnetchargeable: 0,
        }
    },
    validations: {
        manualid: {

            minLength: minLength(6),
            isUnique(value) {
                if (value === '') {
                    return false
                }
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(typeof value === 'string' && value.includes != 'FK-')
                    }, 350 + Math.random() * 300)
                })
            }
        },
        form: {
            saledate: {
                required,
                isUnique(value) {
                    if (value === null) {
                        return false
                    }
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(moment(value).isValid())
                        }, 350 + Math.random() * 300)
                    })
                }
            },
            // useradvisorid:{
            //     required
            // },
            // dealbyid:{
            //     required
            // },
            // salebyid:{
            //     required
            // }
        },
        startdate: {

            isUnique(value) {
                if (value === null) {
                    return false
                }
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(moment(value).isValid())
                    }, 350 + Math.random() * 300)
                })
            }
        }
    },
    mounted() {
        if(window.location.pathname=='/salesmanager'){
			this.$store.commit("updatetrantype",2)
		} 

        this.refresh();
        this.form.saletype = 2;
        this.initializedata();
        if (this.$router.currentRoute.path == '/salesmanager') {
            this.$store.commit("assignedititem", null)
            this.$store.commit("assignloandetails", null)
            this.initializedata();
            this.refresh();
        }
        this.$store.dispatch("fetchregisters");
    },
    computed: {
        ...mapGetters([
            'cartitems', 'edititem', 'categories', 'newtax', 'attributes', 'allproducts', 'referraluser', 'loandetails', 'selectedstoreid',
            'employees', 'trantype', 'totalnetprice', 'amountwithouttax', 'totalnetpriceafterdiscount', 'advisorid', 'registers', 'chargeableledger',
            'referralPoints'
        ]),
        parentcategory() {
            if (this.categories != null) {
                return this.categories.filter(block => block.parent_id == 0)
            }
            return [];
        },
        subcategory() {
            if (this.categories != null) {
                return this.categories.filter(block => block.parent_id == this.parentid)
            }
            return [];
        },
        /*brands(){
            let attribute = this.attributes.find(block=>block.id==2)
            return attribute
        },*/
        predefinedvalues() {
            if (this.brands != null && this.brands.predefinedvalues != null) {
                return this.brands.predefinedvalues
            }
            return [];
        },
        getval(value) {
            return value
        },

        filters() {
            return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
        },
        selectedproduct() {
            if (this.products != null) {
                return this.products.find(block => block.id == this.form.productid)
            }
            return [];
        },
        /* amount(){
             let price = 0;
             if(this.selectedproduct!=null){
                 price = this.totalnetprice
                 if(this.form.saletype==1){
                     price = this.totalnetprice
                 }
             }
             if(this.selectedproduct!=null && this.discount!=null){
                 return (parseFloat(price)-parseFloat(this.discount)).toFixed(2)
             }else{
                 return price
             }
         }*/

    },
    watch: {
        newcustomer: function () {
            console.log(this.newcustomer)
        },
        edititem: function () {
            this.initializedata();
        },
    },
    methods: {
        refresh() {
            this.$store.dispatch("fetchproducts")
            this.$store.dispatch('fetchcategories')
            this.$store.dispatch('fetchattributes')
            this.$store.dispatch('fetchmaster')
            this.$store.dispatch('fetchemployees')
            this.setapplicabletaxes();
            window.axios.post('api/usersequence/fetchsale')
                .then(response => this.processSequenceResponse(response.data))
                .catch(error => console.log(error));
        },
        processSequenceResponse(data) {
            if (this.edititem == null) {
                this.serialno = parseInt(data) + 1
            }
        },
        discountchange() {
            if(this.discount>0 && this.discount!=''){
                let discountledger = []
                let param = { userid: 15819, name: 'Discount', isdeductable: 1, amount: parseFloat(this.discount) }
                discountledger.push(param)
                this.$store.commit('assignchargeableledger', discountledger)
                let amountdiscount = parseFloat(this.totalnetprice) - parseFloat(this.discount) ?? 0
                this.$store.commit("assigntotalnetpriceafterdiscount", amountdiscount)
            }else {
                this.$store.commit("assigntotalnetpriceafterdiscount", this.totalnetprice)
            }
        },
        fetchbrands() {

            let param = { parentcategoryid: this.category_id, attributeid: 2 }
            window.axios.post('api/attribute/values/fetchbycategory', param)
                .then(response => this.processAvailableBrands(response.data))
                .catch(error => console.log(error));
        },
        processAvailableBrands(data) {
            this.brands = data
        },
        initializedata() {

            if (this.edititem != null) {
                this.discount = this.edititem.discount
                this.$store.commit("assigntotalnetpriceafterdiscount", parseFloat(this.edititem.amtafterdiscount))
                console.log("totalnetpriceafterdiscount", this.totalnetpriceafterdiscount)
                this.$store.commit("assigntotalnetprice", parseFloat(this.edititem.amountwithtax))
                this.$store.commit("assignreferraluser", this.edititem.user)
                this.$store.commit("assignloandetails", this.edititem.loandetails)
                this.form.id = this.edititem.id,
                this.form.saledate = this.edititem.transactiondate
                this.serialno = this.edititem.usersequence
                this.form.field1 = this.edititem.field1
                if (this.edititem.advisor != null) {
                    this.form.useradvisorid = this.edititem.advisor.generatedid
                    this.checkadvisor();
                }
                this.form.saletype = this.edititem.saletype
                if (this.edititem.loandetails != null) {
                    this.manualid = this.loandetails.manualid
                    this.startdate = this.loandetails.startdate
                }
                this.form.salebyid = this.edititem.salebyid
                this.form.dealbyid = this.edititem.dealbyid
                this.mode = this.edititem?.payment[0]?.mode
                this.$store.commit('updatetrantype',this.edititem.trantype)
            } else {
                this.form = new window.Form({
                    id: 0, saletype: 2, customerid: 0, productid: 0, color: '', salebyid: 0, saledate: null,
                    paymententries: null, customername: '', mobileno: null, category_id: 0, field1: '',
                    dealbyid: 0

                }),
                this.manualid = 'FK-',
                this.brandid = 0,
                this.products = [],
                this.newcustomer = false,
                this.parentid = 0,
                this.category_id = 0,
                this.discount = 0,
                this.form.useradvisorid = 'VSDIGI-',
                this.$store.commit('assignreferraluser', null),
                this.$store.commit('assigncartitems', []),
                this.$refs.resetid?.resetdata();
                this.$store.commit('assignloadingstatus', 0)
                this.$store.commit("clear")
                this.$store.commit("assigntotalnetpriceafterdiscount", null)
                this.$store.commit("assigntotalnetprice", null)
                this.$store.commit("assignreferraluser", null)
                this.$store.commit("assignloandetails", null)
                this.isadvisor = false
                this.$store.commit('assignreferralPoints',null)
            }
        },
        submit() {
            this.btndisabled = true
            
            if (this.manualid.length < 6 && this.form.saletype==2) {
                this.btndisabled = false
                this.$notify({ text: 'Please fill Lan no.', type: 'error' })
            } else if (this.form.salebyid == 0 || this.isadvisor == false || this.form.dealbyid == 0) {
                console.log(this.$v)
                console.log(this.$v.$invalid)
                this.btndisabled = false
                this.$notify({
                    text: 'Please Fill All the fields',
                    type: 'error',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
            } else if (this.form.saledate == '' || this.form.saledate==null) {
                this.btndisabled = false
                this.$notify({
                    text: 'Please fill sale date.',
                    type: 'error',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
            } else if (this.loandetails?.numberofemi == 0 && this.saletype == 2) {
                this.btndisabled = false
                this.$notify({
                    text: 'No of Emi Cannot be 0',
                    type: 'error',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
            } else if (this.showlanmsg == false) {
                this.btndisabled = false
                this.$notify({
                    text: 'Duplicate Lan No.',
                    type: 'error',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
            } else {
                if (confirm("Do you want to Submit Sales")) {
                    this.$notify({
                        text: 'Sales Submitted',
                        type: 'warn',
                        duration: '-1',
                        speed: '100',
                        closeOnClick: false
                    })
                    this.$store.commit('assignloadingstatus', 1)
                    let obj = {
                        collectedtaxes: null, finalamount: this.selectedproduct?.emi ?? 0,
                        netpricewithouttax: 0, price: 0, product: this.selectedproduct,
                        quantity: 1, unitpricewithouttax: 0
                    }
                    let paymententries = []
                    if (this.form.saletype == 2) {
                        let obj = { amount: this.loandetails.downpayment, inout: 1, mode: this.mode, paymenttype: 5 }
                        paymententries.push(obj);
                        if(this.referralPoints !=null && parseFloat(this.referralPoints.amount) > 0){
                            paymententries.push(this.referralPoints);
                        }
                    } else {
                        let obj = { amount: this.totalnetpriceafterdiscount, inout: 1, mode: this.mode, paymenttype: 2 }
                        paymententries.push(obj);
                    }
                    let cartitems = [];

                    cartitems.push(obj);
                    if (this.referraluser != null) {
                        this.form.customerid = this.referraluser.id
                    }
                    let param = {
                        items: this.cartitems, id: this.id,
                        customerid: this.form.customerid, fy: this.getCurrentFinancialYear(), advisorid: this.advisorid.id,
                        storeid: 10, transactiondate: this.form.saledate == null ? new Date().toISOString().slice(0, 10) : this.form.saledate,
                        transactionid: this.form.id, trantype: 2,
                        storeid2: 0, address_id: 0, amountwithouttax: this.amountwithouttax,
                        barcode: null, discount: this.discount, mode: this.mode,
                        amtafterdiscount: this.totalnetpriceafterdiscount, amountwithtax: Math.round(this.totalnetprice),
                        // amountwithtax: this.totalnetpriceafterdiscount,
                        isinvoice: 1, hsnbasedtaxes: null, usersequence: this.serialno,
                        saletype: this.form.saletype, salebyid: this.form.salebyid,
                        dealbyid: this.form.dealbyid,
                        loandetails: this.loandetails, field1: this.form.field1, totalnetchargeable: this.totalnetchargeable,
                        paymententries: paymententries, oldtrantype: 2, chargeableledger: this.chargeableledger,
                        customername: this.form.customername, mobileno: this.form.mobileno
                    }
                    console.log(param)
                    window.axios.post('api/submittransaction2', param)
                        .then(response => this.processResponse(response.data))
                        .catch(error => console.log(error));
                } else {
                    this.btndisabled = false
                }

            }
        },
        fetchproducts() {
            let param = {
                parentcategoryid: this.category_id, attributeid: 2, attributevalueids: this.brandid,
                storeid: this.selectedstoreid
            }
            window.axios.post('api/products/fetchbyfilters', param)
                .then(response => this.processProductsResponse(response.data))
                .catch(error => console.log(error));
        },
        processProductsResponse(data) {
            this.products = data
        },
        processResponse() {
            this.$notify({
                text: 'Sales Submitted Successfully',
                type: 'success',
                duration: '-1',
                speed: '100',
                closeOnClick: false
            })

            this.initializedata();
            this.refresh();
            this.$store.commit('assignloandetails', null)
            this.$store.commit("clear")

            if (this.edititem != null) {
                this.$store.commit('assignviewno', 1)
                this.$parent.refresh();
            } else {
                window.location.reload();
            }
            this.$store.commit('assignedititem', null)
            this.btndisabled = false
            this.$store.commit('assignloadingstatus', 0)
        },
        addproduct() {
            if (this.parentid != 0) {

                this.productchange();

            } else {
                this.showmsg = true
            }
        },
        productchange() {
            this.showmsg = false
            //this.$store.commit("transactiontypechanged");
            this.setapplicabletaxes();
            this.$store.commit("updatetrantype", 2);
            this.$store.commit("assignselectedproductid", this.form.productid)
            let newitem = this.products.find(block => block.id == this.form.productid)
            let newitem1 = { ...newitem }
            // newitem1.sp = newitem.emi
            if (newitem.retail > 0) {
                newitem1.sp = parseFloat(newitem.cp) * (1 + parseFloat(newitem.retail) / 100)
            } else {
                newitem1.sp = newitem.cp
            }
            newitem1.quantity = this.quantity
            newitem1.barcodes = this.barcodes
            // if (this.form.saletype == 1)
            //     newitem1.sp = newitem.sp
            this.$store.commit('addcartitem', newitem1);
            this.parentid = 0
            this.category_id = 0
            this.brandid = 0
            this.form.productid = 0
            this.allbarcode = []
            this.barcodes = []
            this.quantity = 0,
            this.chooseBarcode = 0
            this.remainquantity = 0
        },
        saletypechange() {
            this.$store.commit("assignsaletype", this.form.saletype)
        },
        reset() {
            this.initializedata();
            this.showcart = false
        },
        back() {
            this.$parent.back();
            this.$store.commit("assignloadingstatus", 0)
            this.$store.commit("assignedititem", null)
            this.$store.commit("assignloandetails", null)
            this.$store.commit('assigncartitems', [])
        },
        setapplicabletaxes() {
            let alltaxes = this.getmastertype(this.MASTER_TAXES)
            let outsideup = false;
            /*if(this.selectedcustomer!=null && this.selectedcustomeraddressid>0){
                let selectedaddress = this.selectedcustomer.addressess.find(block=>block.id==this.selectedcustomeraddressid)
                if(typeof selectedaddress!=='undefined'){
                    if(selectedaddress.state!=this.billingstate){
                        outsideup = true
                    }
                }
            }*/

            if (outsideup) {
                let index = alltaxes.findIndex(block => block.labelid == this.CGST_LABELID)
                if (index > -1)
                    alltaxes.splice(index, 1)
                index = alltaxes.findIndex(block => block.labelid == this.SGST_LABELID)
                if (index > -1)
                    alltaxes.splice(index, 1)
            } else {

                let index = alltaxes.findIndex(block => block.labelid == this.IGST_LABELID)

                if (index > -1)
                    alltaxes.splice(index, 1)
            }

            this.$store.commit("assignapplicabletaxes", alltaxes)
        },
        openproducts() {
            this.showcart = !this.showcart
        },
        checklan() {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                let param = { manualid: this.manualid.toUpperCase() }
                window.axios.post('api/loan/checkexistloan', param)
                    .then(response => this.processlanResponse(response.data))
                    .catch(error => console.log(error));
            }, 1000)
        },
        processlanResponse(data) {
            this.showlanmsg = data.success
            if (data.success) {
                this.loandetails.manualid = this.manualid ?? ''

                // this.manualid
            }
        },
        checkadvisor() {
            let param = { advisorid: this.form.useradvisorid }
            window.axios.post('api/user/downline/fetch/check', param)
                .then(response => this.processAdiveResponse(response.data))
                .catch(error => console.log(error));
        },
        processAdiveResponse(data) {
            if (data.success == true) {
                //alert('Advisor')
                //this.advisordata=data.data
                this.$store.commit("assignadvisorid", data.data)
                this.isadvisor = true
                this.showadvice = false
                console.log(this.advisorid.name)
                console.log(this.advisorid.id)
            } else {
                this.showadvice = true
                this.isadvisor = false
                //alert("not a Advisor")
            }
        },
        getAllBarcode() {
            this.fetchProductQuantity()
            let param = { productid: this.form.productid, storeid: this.selectedstoreid }
            window.axios.post('api/fetch/all/barcodes', param)
                .then((response) => this.processBracodesResponse(response.data))
                .catch((error) => console.log(error))
        },
        processBracodesResponse(data) {
            this.allbarcode = data.barcodes
        },
        fillBarcode() {
            if (this.quantity > this.chooseBarcode) {
                let barcode = this.allbarcode.find(block => block.id == this.barcodeid)
                if (this.barcodes.length > 0) {
                    if (barcode && !this.barcodes.includes(barcode)) {
                        this.barcodes.push(barcode);
                        this.chooseBarcode++
                        this.barcodeid = 0;
                    } else {
                        this.barcodeid = 0
                        this.$notify({
                            text: 'Duplicate entry not allowed.',
                            type: 'warn',
                            duration: '-1',
                            speed: '100',
                            closeOnClick: false
                        })
                    }
                } else {
                    this.barcodes.push(barcode)
                    this.chooseBarcode++
                    this.barcodeid = 0;
                }
            } else {
                this.barcodeid = 0
            }
        },
        checkQuantity() {
            this.chooseBarcode = 0
            this.barcodes = []
            if (parseFloat(this.remainquantity) < parseFloat(this.quantity)) {
                this.$notify({ text: 'Quantity limit exceed', type: 'error' })
                this.quantity = 0
               
            }
        },
        deleteBarcode(item) {
            for (let i = 0; i < this.barcodes.length; i++) {
                if (this.barcodes[i].id == item.id) {
                    this.barcodes.splice(i, 1)
                }
            }
            this.chooseBarcode--
        },
        fetchProductQuantity() {
            let param = { productid: this.form.productid, storeid: this.selectedstoreid }
            window.axios.post('api/product/stock/quantity', param)
                .then((response) => this.processProductQuantity(response.data))
                .catch((error) => console.log(error))
        },
        processProductQuantity(data) {
            this.remainquantity = 0
            let remain = data?.stock
            if (remain != null) {
                this.remainquantity = parseFloat(remain.finalquantity).toFixed(2)
            }
        },

    }
}
</script>
<style scoped>
.inputsearchbtn {
    color: green;
    position: absolute;
    margin-top: -43px;
    margin-left: 86.2%;
    background-color: transparent;
    border: none;
    padding: 0;
}

.ft-49 {
    font-size: 49px;
}

.ft-49:focus-visible {
    border: none;
    outline: none;
}
</style>