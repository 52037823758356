import { render, staticRenderFns } from "./SalesManager.vue?vue&type=template&id=23b70bdc&scoped=true"
import script from "./SalesManager.vue?vue&type=script&lang=js"
export * from "./SalesManager.vue?vue&type=script&lang=js"
import style0 from "./SalesManager.vue?vue&type=style&index=0&id=23b70bdc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b70bdc",
  null
  
)

export default component.exports